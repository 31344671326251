export default () => {

    const message = document.getElementById('message');

    if (message !== null) {
        let start = 0;
        const intervalTime = 20;
        const timeoutTime = 5000;
        const progressBar = document.getElementById('progressBar');

        const interval = setInterval(function () {
            start = start + intervalTime;

            let value = (start * 100) / timeoutTime;
            progressBar.style.width = `${value}%`;
            progressBar.setAttribute('aria-valuenow', value);
            if (timeoutTime === start) {
                $('#message').fadeOut();
                clearInterval(interval);
            }
        }, intervalTime);
    }

}
