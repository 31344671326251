import Swal from 'sweetalert2'
import * as url from "url";

export default () => {
    const elements = document.getElementsByClassName('fa-user-plus');

    Array.prototype.forEach.call(elements, function(element) {
        element.addEventListener('click', function () {
            const time = this.getAttribute('data-value');
            const xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                    // Typical action to be performed when the document is ready:
                    const data = JSON.parse(xhttp.responseText);

                    const table = document.getElementById('meeting-table');
                    table.innerHTML = '';

                    Array.prototype.forEach.call(data, function (item) {
                        if (item.id !== undefined) {
                            table.innerHTML = table.innerHTML + `<td class="meeting-user" data-value="${item.user_id}" data-value-time="${item.id}">${item.name} (<b>${item.company}, ${item.country}</b>)</td>`;
                        }
                    });


                    const items = document.getElementsByClassName('meeting-user');
                    Array.prototype.forEach.call(items, function(user) {
                        user.addEventListener('click', function () {
                            const xhr = new XMLHttpRequest();
                            xhr.onreadystatechange = function() {
                                if (this.readyState === 4 && this.status === 200) {
                                    Swal.fire({
                                        title: 'Success',
                                        text: JSON.parse(this.response).message,
                                        icon: 'success',
                                        confirmButtonText: 'Close'
                                    }).then((result) => {
                                        // Reload the Page
                                        location.reload();
                                    });
                                }

                                if (this.readyState === 4 && this.status === 400) {
                                    Swal.fire({
                                        title: 'Error!',
                                        text: JSON.parse(this.response).message,
                                        icon: 'error',
                                        confirmButtonText: 'Close'
                                    })
                                }
                            }
                            const metas = document.getElementsByTagName('meta');
                            xhr.open("POST", `/save/meeting`, true);
                            let formData = new FormData();
                            formData.append('user_id', this.getAttribute('data-value'));
                            formData.append('id', this.getAttribute('data-value-time'));
                            formData.append('_token', metas.namedItem('csrf-token').getAttribute('content'));
                            xhr.send(formData);
                        });
                    });

                    let fadeTarget = document.getElementById("modal-fdx");
                    fadeTarget.style.display = 'block';
                    fadeTarget.style.opacity = 1;

                }
            };
            xhttp.open("GET", `/freeUsersByTime/${time}`, true);
            xhttp.send();
        });
    });

    document.getElementById('closedModal')?.addEventListener('click', function () {
        let fadeTarget = document.getElementById("modal-fdx");
        let fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
            } else {
                fadeTarget.style.display = "none";
                clearInterval(fadeEffect);
            }
        }, 20);
    });

    const verifieds = document.getElementsByClassName('btn-verified');
    Array.prototype.forEach.call(verifieds, function(verified) {
        verified.addEventListener('click', function (){
            const id = this.getAttribute('data-value');
            const xhr = new XMLHttpRequest()
            xhr.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: JSON.parse(this.response).message,
                        icon: 'success',
                        confirmButtonText: 'Close'
                    }).then((result) => {
                        // Reload the Page
                        location.reload();
                    });
                }

                if (this.readyState === 4 && this.status === 400) {
                    Swal.fire({
                        title: 'Error!',
                        text: JSON.parse(this.response).message,
                        icon: 'error',
                        confirmButtonText: 'Close'
                    })
                }
            }
            const metas = document.getElementsByTagName('meta');
            xhr.open("POST", `/meeting/verified`, true);
            let formData = new FormData();
            formData.append('id', id);
            formData.append('_token', metas.namedItem('csrf-token').getAttribute('content'));
            xhr.send(formData);
        });
    });

    const declineds = document.getElementsByClassName('btn-declined');
    Array.prototype.forEach.call(declineds, function(declined) {
        declined.addEventListener('click', function (){
            const id = this.getAttribute('data-value');
            const xhr = new XMLHttpRequest()
            xhr.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: JSON.parse(this.response).message,
                        icon: 'success',
                        confirmButtonText: 'Close'
                    }).then((result) => {
                        // Reload the Page
                        location.reload();
                    });
                }

                if (this.readyState === 4 && this.status === 400) {
                    Swal.fire({
                        title: 'Error!',
                        text: JSON.parse(this.response).message,
                        icon: 'error',
                        confirmButtonText: 'Close'
                    })
                }
            }
            const metas = document.getElementsByTagName('meta');
            xhr.open("POST", `/meeting/declined`, true);
            let formData = new FormData();
            formData.append('id', id);
            formData.append('_token', metas.namedItem('csrf-token').getAttribute('content'));
            xhr.send(formData);
        });
    });

    const fakeButtons = document.getElementsByClassName('btn-fake');
    Array.prototype.forEach.call(fakeButtons, function (fakeButton) {
        fakeButton.addEventListener('mouseover', function (){
            if(this.getAttribute('data-primary') === "success") {
                this.classList.remove('badge-success');
                this.classList.add('badge-danger');
                this.innerHTML = 'Lock <i class="fas fa-lock"></i>';
            }

            if(this.getAttribute('data-primary') === "danger") {
                this.classList.remove('badge-danger');
                this.classList.add('badge-success');
                this.innerHTML = 'Unlock <i class="fas fa-lock-open"></i>';
            }
        });

        fakeButton.addEventListener('mouseout', function (){
            if(this.getAttribute('data-primary') === "success") {
                this.classList.add('badge-success');
                this.classList.remove('badge-danger');
                this.innerHTML = 'Available <i class="fas fa-lock-open"></i>';
            }

            if(this.getAttribute('data-primary') === "danger") {
                this.classList.add('badge-danger');
                this.classList.remove('badge-success');
                this.innerHTML = 'Locked <i class="fas fa-lock"></i>';
            }
        });

        fakeButton.addEventListener('click', function (){
            const value = this.getAttribute('data-value');
            let url = null;
            if(this.getAttribute('data-primary') === "success") {
                url = `/meeting/lock/${value}`;
            } else {
                url = `/meeting/unlock/${value}`;
            }
            console.log(url);
            const xhr = new XMLHttpRequest()
            xhr.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: JSON.parse(this.response).message,
                        icon: 'success',
                        confirmButtonText: 'Close'
                    }).then((result) => {
                        // Reload the Page
                        location.reload();
                    });
                }

                if (this.readyState === 4 && this.status === 400) {
                    Swal.fire({
                        title: 'Error!',
                        text: JSON.parse(this.response).message,
                        icon: 'error',
                        confirmButtonText: 'Close'
                    })
                }
            }
            const metas = document.getElementsByTagName('meta');
            xhr.open("POST", url, true);
            let formData = new FormData();
            formData.append('_token', metas.namedItem('csrf-token').getAttribute('content'));
            xhr.send(formData);
        });
    });
}
