import Swal from "sweetalert2";

export default () => {

    const elements = document.getElementsByClassName('select-table');

    Array.prototype.forEach.call(elements, function(element) {
        element.addEventListener('change', function () {
            const xhr = new XMLHttpRequest()
            xhr.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: JSON.parse(this.response).message,
                        icon: 'success',
                        confirmButtonText: 'Close'
                    }).then((result) => {
                        // Reload the Page
                        location.reload();
                    });
                }

                if (this.readyState === 4 && this.status === 400) {
                    Swal.fire({
                        title: 'Error!',
                        text: JSON.parse(this.response).message,
                        icon: 'error',
                        confirmButtonText: 'Close'
                    })
                }
            }
            const metas = document.getElementsByTagName('meta');
            const id = this.getAttribute('data-value');
            xhr.open("POST", `/meeting/table`, true);
            let formData = new FormData();
            formData.append('meeting_id', id);
            formData.append('table_id', this.value)
            formData.append('_token', metas.namedItem('csrf-token').getAttribute('content'));
            xhr.send(formData);
        });
    });

}
